import React, { useRef, useState } from "react";
import "./App.css";
import close_btn from "./close_btn.png";
import axios from "axios";
import parse from "html-react-parser";
import emailjs from "@emailjs/browser";
const dealer = "Bayamon";
// const dealer = "Jeep";
// const dealer = "Mitsubishi";
const url = "https://strapi-m8wa.onrender.com";

const baseURL = `${url}/api/dealers?filters[Dealer][$eq]=${dealer}&populate=*`;

const baseLegalesURL = `${url}/api/legales?filters[Dealer][$eq]=${dealer}`;

function App() {
  const [showModal, setShowModal] = useState(false);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [model, setModel] = useState("");
  const [title, setTitle] = useState("");
  const [logo, setLogo] = useState("");
  const [bottomText, setBottomText] = useState("");
  const [bottomPhone, setBottomPhone] = useState("");
  const [bottomAddress, setBottomAddress] = useState("");
  const [legalesInfo, setLegalesInfo] = useState("");
  const [topBackground, setTopBackground] = useState("");
  const [bottomBackground, setBottomBackground] = useState("");
  const form = useRef(null);

  React.useEffect(() => {
    axios
      .get(baseURL, {
        headers: {
          Authorization:
            "Bearer 0caad1ef03c1e0652b72a26123cf063ffce3227509d9a593f55a46afd527e3e4e886212a9bb25bf8da63b9f1548ac2290a7f1b125a785e69b01c36207a6b3ba7ac1d846e80b0498a09b786322582417f3c3d7ad27159f94d4813d234de4972365565537478abdf53f83fc869b046f7fc82b279da500241b972141beede92af37",
        },
      })
      .then((response) => {
        const fields = response.data.data[0].attributes;
        setTitle(fields.Titulo);
        setBottomText(fields.Texto_Footer);
        setBottomPhone(fields.Telefono_Footer);
        setBottomAddress(fields.Direction_Footer);
        setLogo(url + fields.Logo.data.attributes.url);
        setTopBackground(url + fields.Background_Superior.data.attributes.url);
        setBottomBackground(url + fields.Background_Footer.data.attributes.url);
      });
  }, []);

  React.useEffect(() => {
    axios
      .get(baseLegalesURL, {
        headers: {
          Authorization:
            "Bearer 0caad1ef03c1e0652b72a26123cf063ffce3227509d9a593f55a46afd527e3e4e886212a9bb25bf8da63b9f1548ac2290a7f1b125a785e69b01c36207a6b3ba7ac1d846e80b0498a09b786322582417f3c3d7ad27159f94d4813d234de4972365565537478abdf53f83fc869b046f7fc82b279da500241b972141beede92af37",
        },
      })
      .then((response) => {
        const fields = response.data.data[0].attributes;
        setLegalesInfo(fields.Informacion_Legales);
      });
  }, []);

  const clickModal = () => {
    setShowModal(!showModal);
  };

  const handleSubmit = () => {
    const data = {
      fullname,
      email,
      phone,
      interest: model,
    };
    const currentForm: any = form.current;

    if (currentForm == null) return;
    // currentForm.reset();
    emailjs
      .sendForm(
        "service_j5omrqa",
        "template_nfsiqqv",
        currentForm,
        "MH9e8FsdLXBWAjkJj"
      )
      .then(
        (result) => {
          alert("message sent successfully...");
          setFullname("");
          setEmail("");
          setPhone("");
          setModel("");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="body">
      {showModal && (
        <div className="modal">
          <div className="modal-bg"></div>
          <div className="modal-container">
            <a
              onClick={() => clickModal()}
              className="link-block w-inline-block closeBtn"
            >
              <img className="close-text" src={close_btn} alt="Logo" />
            </a>
            <h1 className="legales_title">LEGALES</h1>
            <div className="paragraph">{parse(legalesInfo)}</div>
          </div>
        </div>
      )}

      <div
        className="top_div wf-section"
        style={{ backgroundImage: "url(" + topBackground + ")" }}
      >
        <div className="top_container w-container">
          <div className="w-row">
            <div className="column-2 w-col w-col-6">
              <img src={logo} loading="lazy" alt="" className="image" />
              <div className="logo_text">{title}</div>
              <div className="logo_bottom_text">¡Contigo en TODO CAMINO!</div>
            </div>
            <div className="column w-col w-col-6">
              <div className="form_container w-form">
                <form ref={form}>
                  <div className="form_title">
                    Regístrate para más información
                  </div>
                  <input
                    type="text"
                    className="form_input w-input"
                    name="name"
                    placeholder="Nombre Completo"
                    required
                    value={fullname}
                    onChange={(e) => {
                      setFullname(e.target.value);
                    }}
                  />
                  <input
                    type="email"
                    className="form_input w-input"
                    name="email"
                    data-name="Email"
                    placeholder="Email"
                    required
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <input
                    type="tel"
                    className="form_input w-input"
                    name="phone"
                    data-name="phone"
                    placeholder="Teléfono"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                  <input
                    type="text"
                    className="form_input w-input"
                    name="interest"
                    placeholder="Modelo de Interés"
                    value={model}
                    onChange={(e) => {
                      setModel(e.target.value);
                    }}
                  />
                  <input
                    type="hidden"
                    name="dealer"
                    value="aa798b9fc2e5dd36213a83763_aa@track.leadgogo.com"
                  />
                </form>
                <button
                  onClick={() => handleSubmit()}
                  value="¡REGÍSTRATE!"
                  className="form_btn w-button"
                >
                  ¡REGÍSTRATE!
                </button>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
              <a onClick={() => clickModal()} className="legales_btn w-button">
                LEGALES
              </a>
              <a
                href="https://albericcolon.com/inventario-bayamon"
                target="_blank"
                className="legales_btn w-button"
              >
                INVENTARIO
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="spacer"></div>
      <div
        className="bottom_bg wf-section"
        style={{ backgroundImage: "url(" + bottomBackground + ")" }}
      >
        <div className="bottom_container w-container">
          <div className="bottom_text">{bottomText}</div>
          <div className="bottom_phone">{bottomPhone}</div>
          <div className="bottom_address">{bottomAddress}</div>
        </div>
      </div>
    </div>
  );
}

export default App;
